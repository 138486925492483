import React from 'react';

function GamesPage() {
  return (
    <div>
      <h2>Juegos disponibles</h2>
    </div>
  );
}

export default GamesPage;
