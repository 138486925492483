import React from 'react';

function ProfilePage() {
  return (
    <div>
      <h2>Perfil del usuario</h2>
    </div>
  );
}

export default ProfilePage;
